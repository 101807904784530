import React from "react";
import TeamProfileCard from "../utility/TeamProfileCard";

import Thomas from "../assets/Thomas.jpg";
import John from "../assets/jaf-profilbilde.jpg";

const Team = React.forwardRef(({}, ref) => {
  return (
    <div className="relative py-8" ref={ref}>
      <h1 className="uppercase text-white text-2xl mx-12">Our Team</h1>
      <div className="p-12 m-0 grid lg:grid-cols-2 gap-2 justify-items-center">
        <TeamProfileCard
          name="John Are Forsberg"
          title="CEO"
          cell="+4792269487"
          mail="jaf@lawo-lab.no"
          img={John}
        />
        <TeamProfileCard
          name="Thomas Liane Stokka"
          title="CPO"
          cell="+4793615146"
          mail="tls@lawo-lab.no"
          img={Thomas}
        />
      </div>
    </div>
  );
});

export default Team;
