import React, { useState, useContext } from "react";
import { ReactComponent as Logo } from "../assets/lawolab-logo.svg";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons";

import { Context } from "../context/Context";

const Nav = React.forwardRef(({}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => setIsOpen(!isOpen);

  const [scrollToPosition] = useContext(Context);

  const scrollEffect = (targetRef) => {
    targetRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="relative w-full h-[80px] flex justify-between items-center bg-transparent px-4">
      <div className="text-2xl font-bold text-center uppercase text-white">
        <Logo className="w-32 h-auto relative" />
      </div>

      <ul className="hidden md:flex gap-8 p-6 uppercase">
        <li className="text-white/80 hover:text-white/50 cursor-pointer" onClick={scrollToPosition(400)}>Who we are</li>
        <li className="text-white/80 hover:text-white/50 cursor-pointer" onClick={() => scrollEffect(ref[0])}>Our Product</li>
        <li className="text-white/80 hover:text-white/50 cursor-pointer" onClick={() => scrollEffect(ref[1])}>Our services</li>
        <li className="text-white/80 hover:text-white/50 cursor-pointer" onClick={() => scrollEffect(ref[2])}>Our Team</li>
        <li className="text-white/80 hover:text-white/50 cursor-pointer" onClick={() => scrollEffect(ref[2])}>Contact Us</li>
      </ul>

      <IconContext.Provider value={{ color: "gray" }}>
        <div onClick={handleClick} className="md:hidden z-20">
          {!isOpen ? <FaBars /> : <FaTimes />}
        </div>
      </IconContext.Provider>
      {/* Mobile Menu */}
      <ul
        className={
          !isOpen
            ? "hidden"
            : "fixed top-0 left-0 w-full h-screen bg-[#000000] flex flex-col justify-center items-center text-white/75 z-10"
        }
        onClick={handleClick}
      >
        <li className="py-6 text-4xl" onClick={scrollToPosition(400)}>
          Who we are
        </li>
        <li className="py-6 text-4xl" onClick={() => scrollEffect(ref[0])}>
          Our Product
        </li>
        <li className="py-6 text-4xl" onClick={() => scrollEffect(ref[1])}>
          Our services
        </li>
        <li className="py-6 text-4xl" onClick={() => scrollEffect(ref[2])}>
          Our Team
        </li>
        <li className="py-6 text-4xl" onClick={() => scrollEffect(ref[2])}>Contact Us</li>
      </ul>
    </div>
  );
});

export default Nav;
