import React from "react";
import icelandLichtensitenNorwayGrantsImage from "../assets/iceland_lichtenstein_norway_grants.png";

const About = () => {
  return (
    <>
      <div className="max-w{1240px] mx-auto grid md:grid-cols-2 bg-inherit flex items-center">
        <div className="">
          <h1 className="uppercase text-white text-2xl mx-12">About Us</h1>
          <p className="max-w-[520px] text-white/60 my-24 mx-12">
            Lawo Lab is a company based out of a small town in the south of Norway
            with an international aim. Started by 4 main founders in 2018, our
            goal is to innovate and improve solutions for our clients. With a wide
            base of experience from several types of industries we offer a new
            look at old problems. Core business is software development and
            hardware prototyping with the capability to initiate projects from
            start to finish in house.
          </p>
        </div>

        <div>
          {/* About Us Table of info */}
          <div className="relative text-center">
            <ul className="flex lg:flex-row flex-col justify-center items-center">
              <li className="p-12">
                <h1 className="text-4xl text-white font-bold">10-15</h1>
                <p className="p-2 text-white/50">Projects in development</p>
              </li>
              <li className="p-12">
                <h1 className="text-4xl text-white font-bold">10+</h1>
                <p className="p-2 text-white/50">Employees</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="max-w{1240px] mx-auto">
        <img
          className="rounded-[0.5rem] mx-12"
          width={329}
          height={123}
          src={icelandLichtensitenNorwayGrantsImage}
          alt="project images"
        />
        <h3 className="my-4 text-[1.25rem] font-bold text-white/80 px-12">
          Working together for a <span style={{color: "#00B050"}}>green</span>, <span style={{color: "#FF0000"}}>competitive</span>, and <span style={{color: "#0070c0"}}>inclusive</span> Europe
        </h3>
        <p className="text-white/60 px-12">
        is not just a slogan. It's a shared vision and a strategic imperative for the European Union (EU) and its member states. For us, Lawo Lab AS  and project partner from Lithuania JSC „Ausne“ this vision encompasses several key priorities: green transition, competitiveness, inclusivity and collaboration. Our common project title „Increasing the Competitiveness of JSC “Ausnė” by Implementing Green Smart Technologies in Production and Creating the Innovative Product “AURAVITA”.  The Goal of the Project – to create innovative product “AURAVITA” (“Aura” is a trademark reference, while “vita” is a Latin for “life”), implementing smart information and communication (IC) solutions while strengthening long-term Norway and Lithuania relations. Project will have significant impact on reducing both economic and social differences in Lithuania, increasing Lithuanian furniture industry competitiveness, as well as having positive effect on life expectancy.  Creating innovative, green production by the project will bring great benefits to consumers to have ability use more healthy, green, cost-effective, comfortable products. Project significantly will contribute bilateral cooperation between Norway and Lithuania presenting and organizing together innovative products marketing activities, promote partnership added value for other key stakeholders. This project is supported by 2014-2021 Programs of the Norwegian Financial Mechanism.
        </p>
      </div>
    </>
  );
};

export default About;
