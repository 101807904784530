const TeamProfileCard = ({ name, title, cell, mail, img }) => {
  return (
    <div className="border-[1px] rounded-lg flex flex-col md:flex-row whitespace-wrap">
      <img
        className="rounded-lg w-[auto] object-cover aspect-[3/4] max-h-[40vh]"
        src={img}
        alt="pfp"
      />
      <div className="flex flex-col justify-between m-4">
        <div>
          <h3 className="text-2xl font-bold text-white">{name}</h3>
          <p className="text-white/50">{title}</p>
        </div>
        <div className="mt-8">
          <p className="text-white/40 whitespace-auto">
            Cell:{" "}
            <a
              className="text-white/80 hover:text-white/50"
              href={"tel:" + cell}
            >
              {cell}
            </a>
          </p>
          <p className="text-white/40 whitespace-auto">
            Mail:{" "}
            <a
              className="text-white/80 hover:text-white/50"
              href={"mailto:" + mail}
            >
              {mail}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
export default TeamProfileCard;
