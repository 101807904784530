import React from "react";

const Card = ({ title, img, desc }) => {
  return (
    <li className="bg-[#121119] border-[0.1px] rounded-[0.5rem] pb-4 last:mb-0">
      <img
        className="rounded-[0.5rem_0.5rem_0_0] w-[100%] object-fit aspect-[4/3] max-h-[60vh]"
        src={img}
        alt="project images"
      />
      <h3 className="m-4 text-[1.25rem] font-bold text-white/80">{title}</h3>
      <p className="mx-4 text-white/50">{desc}</p>
    </li>
  );
};

export default Card;
