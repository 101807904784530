import React, {useRef} from 'react';

import Nav from "../components/Nav";
import HomeSection from "../components/HomeSection";
import About from "../components/About";
import Product from "../components/Product";
import Services from "../components/Services";
import Team from "../components/Team";
import Footer from "../components/Footer";
import ScrollToTop from "../utility/ScrollToTop";

const Homepage = () => {

  const productRef = useRef(null);
  const servicesRef = useRef(null);
  const teamRef = useRef(null);

  return (
    <div>
      <Nav ref={[productRef, servicesRef, teamRef]}/>
      <HomeSection />
      <About />
      <Product ref={productRef}/>
      <Services ref={servicesRef}/>
      <Team ref={teamRef}/>
      <ScrollToTop />
    </div>
  );
};

export default Homepage;
