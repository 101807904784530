import React from "react";
import Image from "../assets/placeholder.png";
import { HiArrowNarrowRight } from "react-icons/hi";

const HomeSection = () => {
  return (
    <div name="home" className="w-full bg-[#121119]">
      {/* Home Section */}
      <div className="max-w[1240px] mx-auto grid md:grid-cols-2">
        <div className="mx-auto my md:max-w-[720px] flex flex-col justify-center xl:pl-24 pl-8">
          <h1 className="text-[#F6F6F6] font-bold shadow-slate-400 text-4xl md:text-8xl font-[Konexy Personal Use]">
            The Appliance of Innovation
          </h1>
          {/* 
          <p className="text-white/50 flex justify-start items-center py-12">
            <HiArrowNarrowRight className="mx-2 " /> Contact Us
          </p>
        */}
        </div>

        <div className="mx-auto my-4 max-w-[auto] flex flex-col justify-center items-center">
          <img src={Image} alt="placeholder" className="w-[auto] h-full mt-8" />
        </div>
      </div>
    </div>
  );
};

export default HomeSection;
