import React from "react";

const Services = React.forwardRef(({}, ref) => {
  return (
    <div className="relative py-8" ref={ref}>
      <h1 className="uppercase text-white text-2xl mx-12">Our Services</h1>
      <div>
        <ul className="flex xl:flex-row flex-col justify-center my-8 shadow-sm shadow-[#7C7C7C]">
          <li className="p-12 shadow-sm shadow-[#7C7C7C]">
            <h3 className="text-2xl text-white uppercase font-bold">
              Printing and design
            </h3>
            <p className="text-white/40 my-4">
              We offer 3D printing and design on request and as part of
              prototyping projects.
            </p>
          </li>
          <li className="p-12 shadow-sm shadow-[#7C7C7C]">
            <h3 className="text-2xl text-white uppercase font-bold">
              Startup and Consulting
            </h3>
            <p className="text-white/40 my-4">
              We can provide consulting services to startup companies.
            </p>
          </li>
          <li className="p-12 shadow-sm shadow-[#7C7C7C]">
            <h3 className="text-2xl text-white uppercase font-bold">
              Software Development
            </h3>
            <p className="text-white/40 my-4">
              We have long experience in software architecture, frontend,
              backend, databases, embedded devices and more.
            </p>
          </li>
          <li className="p-12 shadow-sm shadow-[#7C7C7C]">
            <h3 className="text-2xl text-white uppercase font-bold">
              Offshore / Onshore service Engineers
            </h3>
            <p className="text-white/40 my-4">
              Lawo Lab can supply highly trained mechatronics and electronics
              engineers. Services that can be provided:
            </p>
            <ul className="text-white/60 font-bold list-disc mx-4">
              <li>CCTV</li>
              <li>PAGA</li>
              <li>PLS</li>
              <li>Ethernet Networking</li>
            </ul>
          </li>
          <li className="p-12 shadow-sm shadow-[#7C7C7C]">
            <h3 className="text-2xl text-white uppercase font-bold">
              Production Line Startup
            </h3>
            <p className="text-white/40 my-4">
              Lawo Lab has a wide network of high end East Asian manufacturers.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
});

export default Services;
