import React from "react";
import Card from "../utility/Card";

// Image imports
import LL1000Image from "../assets/project-ll1000.png";
import toolManagementImage from "../assets/tool-management.png";
import seapenImage from "../assets/seapen-logo.svg";

const Product = React.forwardRef(({}, ref) => {
  return (
    <div className="w-full bg-inherit py-8" ref={ref}>
      <h1 className="uppercase text-white text-2xl mx-12">Our Product</h1>
      <ul className="list-none p-8 m-0 grid lg:grid-cols-3 gap-6">
        <Card
          title="Project LL1000"
          desc="Lawo Lab have designed and developed a tongue weight control system for car trailers for easy use with a mobile phone application for aid in use of the trailer within the safety rules and regulations."
          img={LL1000Image}
        />
        <Card
          title="Tool Management System"
          desc="A solution for material control, providing fully automatic check-in and checkout of company materiel. Lawo Lab delivered custom hardware and software."
          img={toolManagementImage}
        />
        <Card
          title="Seapen"
          desc="The payment platform you've been waiting for!"
          img={seapenImage}
        />
      </ul>
    </div>
  );
});

export default Product;
