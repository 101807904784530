import React, { createContext } from "react";

export const Context = createContext();

/* Can be used as a theme provider */

export const Provider = ({ children }) => {
    
    function scrollToPosition(position) {
        return () => window.scrollTo({ top: position, behavior: "smooth" });
    }

  return <Context.Provider value={[scrollToPosition]}>{children}</Context.Provider>;
};
