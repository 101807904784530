import React from "react";
import Homepage from "./pages/Homepage";

function App() {
  return (
    <div className="bg-[#121119]">
      <Homepage />
    </div>
  );
}

export default App;
