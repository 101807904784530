import React, { useEffect, useState } from "react";
import { useWindowScroll } from "react-use";
import { IoIosArrowDropup } from "react-icons/io";
import { IconContext } from "react-icons";

const ScrollToTop = () => {
  const { y: pageYOffset } = useWindowScroll();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (pageYOffset > 400) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [pageYOffset]);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  if (!visible) {
    return false;
  }

  return (
    <div
      className="fixed bottom-3 right-3 z-10 cursor-pointer text-white/20 hover:text-white/50"
      onClick={scrollToTop}
    >
      <IconContext.Provider value={{size: 50}}>
        <IoIosArrowDropup />
      </IconContext.Provider>
    </div>
  );
};

export default ScrollToTop;
